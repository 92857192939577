.card {
    background-color: #f8f9fa;
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 20px;
    margin: 10px;
}

.card h3 {
    color: #343a40;
    margin-bottom: 10px;
}

.card p {
    color: #fff;
    font-size: 14px;
    line-height: 1.6;
}

.slick-prev:before,
.slick-next:before {
    color: #000;
}