h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Times New Roman", Times, serif;
}

p,
span {
  font-family: "Arial", sans-serif;
}

.message-right {
  text-align: end;
  display: flex;
  justify-content: flex-end;
}

.message-left {
  display: flex;
  justify-content: flex-start;
}

.message-center {
  text-align: center;
  display: flex;
  justify-content: center;
}

.message-right p {
  padding: 5px 10px;
  border: 1px solid grey;
  border-radius: 20px;
  width: max-content;
  background: #d6fff5;
  color: #1a221e;
  display: flex;
  flex-direction: column;
}

.message-left p {
  padding: 5px 10px;
  border: 1px solid grey;
  border-radius: 20px;
  width: max-content;
  background: #e0e9ff;
  color: #1a221e;
  display: flex;
  flex-direction: column;
}

.text-body-secondary,
.text-muted {
  color: #fff !important;
}

.carousel.slide {
  padding: 50px;
}

.carousel-indicators [data-bs-target] {
  background: #1e1e1e;
  height: 5px;
  border: none;
}

.carousel-control-next,
.carousel-control-prev {
  color: #000;
}

.css-b62m3t-container {
  width: 35%;
}

.card.shadow-sm:hover,
.card.text-black.h-100:hover {
  box-shadow: 0px 5px 10px 0 #000 !important;
  margin-top: revert-layer;
}

#loader {
  animation: Loader .5s linear infinite;
}

.list-group.list-group-flush li:hover {
  background: #e3e3e3 !important;
}

@media screen and (max-width: 768px) {
  .titulo-absoluto h2 {
    font-size: initial !important;
  }

  .h3-home {
    font-size: xx-large !important;
  }

  .container_inicio h1 span {
    background: linear-gradient(90deg, #327eab, #355d92) !important;
  }

  .img-inicio {
    width: 65% !important;
  }

  footer .col-md-12,
  .container_inicio,
  .login,
  .profile {
    flex-direction: column !important;
  }

  .profile {
    align-items: start !important;
  }

  .login,
  .profile {
    gap: 10px;
  }

  .card-body .d-flex.justify-content-between.align-items-center {
    flex-direction: column !important;
    align-items: start !important;
    gap: 10px;
  }

  .register {
    width: 100% !important;
  }
}

@keyframes Loader {
  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}