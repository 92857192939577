body {
    font-family: Arial, sans-serif;
    background-color: #f2f2f2;
    margin: 0;
    padding: 0;
}

.container-body {
    max-width: 600px;
    margin: 20px auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header {
    background-color: #007bff;
    color: #fff !important;
    padding: 10px;
    text-align: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.content {
    padding: 20px;
}

h1,
h2,
h3 {
    margin: 0;
    color: #333;
}

p {
    margin: 5px 0;
    color: #666;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin-bottom: 5px;
    color: #666;
}