.titulo-absoluto {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50px !important;
    box-shadow: 0px 5px 15px 0 #000;
}

.descripcion-relativo {
    position: relative;
    margin-top: 50px;
    padding-top: 50px !important;
    box-shadow: 0px 5px 15px 0 #000;
}

.pt-inherit {
    padding-top: inherit !important;
}